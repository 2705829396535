define("sirvo-web/templates/inventario/movimientos/salidas/detalles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DIVLjCO4",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"bs-modal\",null,[[\"size\",\"onHide\"],[\"lg\",[26,\"action\",[[21,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"\\n  \"],[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"\\n    \"],[6,\"h4\"],[10,\"class\",\"modal-title\"],[8],[0,\"\\n      Salida \"],[1,[21,0,[\"record\",\"numero\"]],false],[0,\" - \"],[1,[21,0,[\"record\",\"fechaFormatted\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\\n  \"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"\\n    \"],[1,[26,\"tables/paginated-table\",null,[[\"model\",\"columns\",\"modelName\",\"modelClassObject\",\"modelFilters\",\"includedRelationships\",\"pageSize\",\"tableClassNames\"],[[21,0,[\"model\",\"detalles\"]],[21,0,[\"columns\"]],\"kar-movdet\",[21,0,[\"modelClass\"]],[21,0,[\"modelFilters\"]],[21,0,[\"includedRelationships\"]],10,\"ember-table thead-gray\"]]],false],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\\n  \"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"unless\",[[21,0,[\"registroCerrado\"]]],null,{\"statements\":[[0,\"      \"],[4,\"bs-button\",null,[[\"type\",\"onClick\"],[\"success\",[26,\"action\",[[21,0,[]],\"addDetail\"],null]]],{\"statements\":[[0,\"\\n        Agregar detalle\\n      \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"bs-button\",null,[[\"size\",\"onClick\"],[\"sm\",[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null]]],{\"statements\":[[0,\"\\n      Cerrar\\n    \"]],\"parameters\":[]},null],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/movimientos/salidas/detalles.hbs"
    }
  });

  _exports.default = _default;
});