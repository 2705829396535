define("sirvo-web/routes/inventario/movimientos/salidas/detalles", ["exports", "servir-ember-utilities/routes/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    request: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    modelName: 'kar-mov',
    indexRoute: 'inventario.movimientos.salidas',
    validations: Ember.computed(function () {
      return {};
    })
  });

  _exports.default = _default;
});