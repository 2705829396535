define("sirvo-web/routes/inventario/procesos/cierre-costos", ["exports", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    validations: Ember.computed(function () {
      return {
        empresa: (0, _validators.validatePresence)(true)
      };
    }),

    model() {
      return {};
    },

    setupController(controller
    /*, model*/
    ) {
      const cierreCostoModel = new Ember.Object({
        empresa: this.company,
        lastCierreFecha: this.fechaCierre || null,
        fecha: new Date(),
        esCierre: true,
        esApertura: false,
        motivo: null
      });
      controller.set('changeset', new _emberChangeset.default(cierreCostoModel, (0, _emberChangesetValidations.default)(this.validations), this.validations));
    }

  });

  _exports.default = _default;
});