define("sirvo-web/routes/inventario/procesos/inventario-fisico/articulos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params
    /*, transition*/
    ) {
      return Ember.RSVP.hash({
        lista: Ember.A([]),
        inventario: this.store.findRecord('kar-inventario-fisico', params.id)
      });
    },

    setupController(controller
    /*, model*/
    ) {
      this._super(...arguments);

      controller.set('reload', true);
    }

  });

  _exports.default = _default;
});