define("sirvo-web/routes/inventario/movimientos/ingresos/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    request: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    modelName: 'kar-mov',
    indexRoute: 'inventario.movimientos.ingresos',
    validations: Ember.computed(function () {
      return {
        serie: (0, _validators.validatePresence)(true),
        numero: (0, _validators.validatePresence)(true),
        proveedor: (0, _validators.validatePresence)(true),
        fecha: (0, _validators.validatePresence)(true)
      };
    }),
    relatedModelsToQuery: Ember.computed('isNew', function () {
      let relatedModels = [];

      if (this.isNew) {
        relatedModels.push({
          name: 'detalles',
          presetValue: Ember.A([])
        });
      } else {
        relatedModels.push({
          name: 'detalles',
          modelName: 'kar-movdet',
          queryParams: {
            filter: {
              movimiento: this.recordId
            }
          }
        });
      }

      relatedModels.push({
        name: 'tipoMovIngreso',
        modelName: 'kar-tipomov',
        queryParams: {
          filter: {
            'tipomov': 'INGRESOS'
          }
        }
      }, {
        name: 'documentosIngreso',
        modelName: 'kar-doc',
        queryParams: {
          filter: {
            empresa: this.company.id,
            codsistema: 'KAR',
            deha: 'D',
            'tipoMovimiento.tipomov': 'INGRESOS',
            // Hardcodeado porque no sabemos como localizarlo.
            codtipo: {
              exact: 'FA'
            }
          }
        }
      }, {
        name: 'tiposUbicaciones',
        modelName: 'kar-articulo-tipo-ubicacion',
        queryParams: {
          filter: {
            empresa: this.company.id
          }
        }
      });
      return relatedModels;
    }),

    createRecordInstance() {
      return this.store.createRecord(this.modelName, {
        empresa: this.company,
        fecha: new Date()
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      let movIngreso = model.tipoMovIngreso.findBy('tipomov', 'INGRESOS');
      let docIngreso = model.documentosIngreso.get('firstObject');

      if (!movIngreso) {
        this.toast.error('No se encontró el tipo de movimiento de "INGRESOS".');
        return this.transitionTo(this.indexRoute);
      }

      if (!docIngreso) {
        this.toast.error('No se encontró un tipo de documento para el ingreso.');
        return this.transitionTo(this.indexRoute);
      }

      Ember.setProperties(model.record, {
        tipoMovimiento: movIngreso,
        tipoDocumento: docIngreso,
        deha: docIngreso.get('deha'),
        usacnt: docIngreso.get('usaCnt')
      });

      if (this.fechaCierre >= model.record.get('fecha')) {
        this.toast.warning('Registro solo de lectura por cierre de inventario.');
      }

      controller.set('items', model.detalles.toArray());
      controller.createNewLinewChangeset();
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});