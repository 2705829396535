define("sirvo-web/routes/inventario/procesos/inventario-fisico/articulos/record/articulo", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    company: Ember.computed.readOnly('currentCompany.company'),

    model() {
      let superData = this._super(...arguments);

      let inventarioFisico = superData.record.get('inventarioFisico');

      if (inventarioFisico.get('cerrado')) {
        this.toast.error('Inventario cerrado.');
        return this.transitionTo(this.parentRoute);
      }

      if (!inventarioFisico.get('bodega.id')) {
        return Ember.RSVP.hash({
          articulos: this.store.query('kar-articulo', {
            filter: {
              empresa: this.company.id,
              debaja: 0
            },
            sort: 'descripcion'
          })
        }).then(routeData => ({ ...superData,
          ...routeData
        }));
      }

      return Ember.RSVP.hash({
        articulosDet: this.store.query('kar-bodega-det', {
          filter: {
            empresa: this.company.id,
            bodega: inventarioFisico.get('bodega.id'),
            // WARNING: Este filtro todavía no se aplica en el backend.
            articulo: {
              debaja: 0
            }
          },
          sort: 'articulo.descripcion',
          include: 'articulo'
        })
      }).then(({
        articulosDet
      }) => ({ ...superData,
        articulos: articulosDet.mapBy('articulo').rejectBy('debaja')
      }));
    }

  });

  _exports.default = _default;
});