define("sirvo-web/controllers/facturacion/movimientos/emision-recibo/record/certificacion-impresion", ["exports", "servir-ember-utilities/controllers/abstract-module-record", "sirvo-web/mixins/sirvo-web-file-saver"], function (_exports, _abstractModuleRecord, _sirvoWebFileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_sirvoWebFileSaver.default, {
    company: Ember.computed.readOnly('currentCompany.company'),
    directorioActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editDirectorio.bind(this)
      }];
    }),

    postSave(record) {
      this.getFileFromServer(`/api/recibo-donacion/${record.id}`);
    },

    actions: {
      onClose() {
        Ember.set(this.changeset, 'firmarDocumento', 0);
        this.transitionToRoute(this.parentRoute);
      },

      descargarRecibo() {
        this.getFileFromServer(`/api/recibo-donacion/${this.record.id}`);
      },

      editDirectorio() {
        if (this.record.firmaElectronica) {
          this.toast.warning('No se puede modificar el directorio.');
          return;
        }

        this.transitionToRoute(`${this.parentRoute}.directorio`);
      }

    }
  });

  _exports.default = _default;
});