define("sirvo-web/controllers/inventario/procesos/inventario-fisico/articulos/record/articulo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      articuloUpdated() {
        // Resetear la bodega si no es el valor heredado de la cabecera.
        if (!this.changeset.get('inventarioFisico').get('bodega.id') && this.changeset.get('bodega.id')) {
          this.changeset.set('bodega', null);
        }
      },

      onClose() {
        this.transitionToRoute(this.parentRoute);
      }

    }
  });

  _exports.default = _default;
});