define("sirvo-web/controllers/inventario/procesos/inventario-fisico/index", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/models/kar-inventario-fisico"], function (_exports, _abstractModuleIndex, _karInventarioFisico) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    modelClass: _karInventarioFisico.default,
    reload: true,
    editRoute: 'inventario.procesos.inventario-fisico.index.record',
    modelFilters: Ember.computed(function () {
      return [{
        selectedFilter: {
          filterName: 'empresa'
        },
        value: this.company.get('id')
      }];
    }),
    modelSortingProperties: Ember.computed(function () {
      return [{
        propertyName: 'correlativo'
      }];
    }),
    columns: Ember.computed(function () {
      return [{
        label: 'Fecha',
        valuePath: 'fechaFormatted',
        sortingPropertyName: 'fecha',
        width: '110px'
      }, {
        label: 'Correlativo.',
        valuePath: 'correlativo',
        width: '100px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          details: {
            title: 'Detalles',
            buttonClass: 'btn-warning',
            icon: 'list',
            action: this.actions.goToDetails.bind(this)
          },
          compare: {
            title: 'Comparar',
            buttonClass: 'btn-info',
            icon: 'calendar-times-o',
            action: this.actions.compare.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }];
    }),

    reloadTable() {
      this.set('reload', true);
    },

    actions: {
      addRecord() {
        this.transitionToRoute(this.editRoute, 'new');
      },

      goToDetails(inventario) {
        this.transitionToRoute('inventario.procesos.inventario-fisico.articulos', inventario.id);
      },

      compare(inventario) {
        this.transitionToRoute('inventario.procesos.inventario-fisico.index.comparar', inventario.id);
      }

    }
  });

  _exports.default = _default;
});