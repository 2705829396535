define("sirvo-web/routes/inventario/procesos/inventario-fisico/index/comparar", ["exports", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators", "moment"], function (_exports, _emberChangeset, _emberChangesetValidations, _validators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    request: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    validations: Ember.computed(function () {
      return {
        empresa: (0, _validators.validatePresence)(true),
        anio: (0, _validators.validatePresence)(true),
        inventarioFisico: (0, _validators.validatePresence)(true),
        inventarioTeorico: (0, _validators.validatePresence)(true)
      };
    }),

    model(params
    /*, transition*/
    ) {
      return Ember.RSVP.hash({
        inventarioFisico: this.store.findRecord('kar-inventario-fisico', params.id),
        inventariosTeoricos: this.request.get('kar/cierres-anio', {
          empresa: this.company.id,
          anio: new Date().getFullYear()
        })
      }).then(modelData => {
        modelData.inventariosTeoricos.forEach(inventarioTeorico => {
          let momentDate = (0, _moment.default)(inventarioTeorico.fecha);
          Ember.setProperties(inventarioTeorico, {
            fechaFormatted: momentDate.isValid() ? momentDate.format('DD/MM/YYYY') : null
          });
        });
        return modelData;
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      let requestData = new Ember.Object({
        empresa: this.company,
        inventarioFisico: model.inventarioFisico,
        inventarioTeorico: null,
        soloLineasConDiferencia: true,
        anio: new Date().getFullYear()
      });
      controller.set('changeset', new _emberChangeset.default(requestData, (0, _emberChangesetValidations.default)(this.validations), this.validations));
    }

  });

  _exports.default = _default;
});