define("sirvo-web/models/kar-inventario-fisico-articulo", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    inventarioFisico: (0, _model.belongsTo)('kar-inventario-fisico'),
    inventarioFisicoConteo: (0, _model.belongsTo)('kar-inventario-fisico-conteo'),
    articulo: (0, _model.belongsTo)('kar-articulo'),
    bodega: (0, _model.belongsTo)('kar-bodega'),
    existencia: (0, _model.attr)('string'),
    observaciones: (0, _model.attr)('string') // creusr: attr('string'),
    // fecusr: attr('string'),
    // codusr: attr('string'),

  });

  _exports.default = _default;
});