define("sirvo-web/services/kardex", ["exports", "ember-changeset", "ember-changeset/utils/is-changeset", "ember-changeset-validations", "sirvo-web/utils/format", "sirvo-web/validations/kar-mov-det-ubicacion", "sirvo-web/validations/kar-mov-det-ubicacion-destino"], function (_exports, _emberChangeset, _isChangeset, _emberChangesetValidations, _format, _karMovDetUbicacion, _karMovDetUbicacionDestino) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    request: Ember.inject.service(),
    loader: Ember.inject.service(),
    constants: Ember.inject.service(),

    onChangesetArticuloUpdated(changeset) {
      (true && !((0, _isChangeset.default)(changeset)) && Ember.assert('Argument 1 must be an instance of Changeset', (0, _isChangeset.default)(changeset)));
      this.resetChangesetCalculo(changeset);
      Ember.setProperties(changeset, {
        unidadMedida: null,
        lote: null
      });

      if (!changeset.get('articulo.id')) {
        return Ember.RSVP.resolve({
          unidadesMedida: Ember.A([])
        });
      }

      let uMedidaFilters = {
        articulo: changeset.get('articulo.id')
      };

      if (changeset.get('movimiento').get('deha') === 'D') {
        uMedidaFilters.compra = 1;
      } else if (changeset.get('movimiento').get('deha') === 'H') {
        uMedidaFilters.venta = 1;
      }

      this.loader.setLoading(true);
      return Ember.RSVP.hash({
        unidadesMedida: this.store.query('kar-tabla-medida', {
          filter: uMedidaFilters
        })
      }).then(articuloData => {
        changeset.set('unidadMedida', articuloData.unidadesMedida.get('firstObject') || null);
        return articuloData;
      }).finally(() => this.loader.setLoading(false));
    },

    resetChangesetCalculo(changeset) {
      (true && !((0, _isChangeset.default)(changeset)) && Ember.assert('Argument 1 must be an instance of Changeset', (0, _isChangeset.default)(changeset)));
      Ember.setProperties(changeset, {
        precioUnitario: 0,
        subtotal: 0,
        valorNeto: 0,
        total: 0,
        valorNetoMe: 0,
        cantidadBase: 0
      });
    },

    updateChangesetCalculo(changeset, articuloSaldo, indexCtrl) {
      (true && !((0, _isChangeset.default)(changeset)) && Ember.assert('Argument 1 must be an instance of Changeset', (0, _isChangeset.default)(changeset)));
      let unidadMedidaCantidad = changeset.get('unidadMedida.cantidad') || 0;
      let precioUnitario = articuloSaldo['valor_promedio']; // En los movimientos de AJUSTE, el usuario es quien ingresa el precio unitario
      // o se calcula a partir del total ingresado.

      if (changeset.get('movimiento').get('tipoMovimiento').get('tipomov') === 'AJUSTE') {
        precioUnitario = changeset.get('precioUnitario');
      }

      let totalNeto = changeset.get('cantidad') * precioUnitario || 0;
      let cantidadBaseFactor = changeset.get('movimiento').get('deha') === 'D' ? 1 : -1;
      Ember.setProperties(changeset, {
        precioUnitario,
        subtotal: totalNeto,
        valorNeto: totalNeto,
        total: totalNeto,
        valorNetoMe: changeset.get('tasac') ? (0, _format.round)(totalNeto / changeset.get('tasac')) : 0,
        // WARNING: Este valor se sobre escribe en el backend para asegurar la consistencia.
        cantidadBase: changeset.get('cantidad') * unidadMedidaCantidad * cantidadBaseFactor
      });

      if (indexCtrl) {
        indexCtrl.setProperties({
          existenciaBase: articuloSaldo['existencia'],
          existencia: articuloSaldo['existencia'] / (unidadMedidaCantidad || 1)
        });
      }

      return Ember.RSVP.resolve(true);
    },

    onChangesetBodegaUpdated(changeset, indexCtrl) {
      (true && !((0, _isChangeset.default)(changeset)) && Ember.assert('Argument 1 must be an instance of Changeset', (0, _isChangeset.default)(changeset))); // Solo los movimientos del HABER requieren validar la existencia.

      if (changeset.get('movimiento').get('deha') !== this.constants.HABER) {
        return Ember.RSVP.resolve(true);
      }

      this.resetChangesetCalculo(changeset);

      if (!changeset.get('articulo.id') || !changeset.get('bodega.id')) {
        return Ember.RSVP.resolve(true);
      }

      this.loader.setLoading(true);
      return this.request.get(`kar-articulos/${changeset.get('articulo.id')}/saldo`, {
        bodegaId: changeset.get('bodega.id')
      }).then(articuloSaldo => this.updateChangesetCalculo(changeset, articuloSaldo, indexCtrl)).finally(() => this.loader.setLoading(false));
    },

    onArticuloUbicacionUpdated(changeset, ubicacionChangeset, indexCtrl) {
      (true && !((0, _isChangeset.default)(changeset)) && Ember.assert('Argument 1 must be an instance of Changeset', (0, _isChangeset.default)(changeset)));
      (true && !((0, _isChangeset.default)(ubicacionChangeset)) && Ember.assert('Argument 2 must be an instance of Changeset', (0, _isChangeset.default)(ubicacionChangeset))); // Solo los movimientos del HABER requieren validar la existencia.

      if (changeset.get('movimiento').get('deha') !== this.constants.HABER) {
        return Ember.RSVP.resolve(true);
      }

      this.resetChangesetCalculo(changeset);

      if (!changeset.get('articulo.id') || !ubicacionChangeset.get('articuloUbicacion.id')) {
        return Ember.RSVP.resolve(true);
      }

      this.loader.setLoading(true);
      return this.request.get(`kar-articulos/${changeset.get('articulo.id')}/saldo`, {
        ubicacionId: ubicacionChangeset.get('articuloUbicacion.id')
      }).then(articuloSaldo => this.updateChangesetCalculo(changeset, articuloSaldo, indexCtrl)).finally(() => this.loader.setLoading(false));
    },

    onChangesetUnidadMedidaUpdated(changeset, indexCtrl) {
      (true && !((0, _isChangeset.default)(changeset)) && Ember.assert('Argument 1 must be an instance of Changeset', (0, _isChangeset.default)(changeset)));
      let unidadMedidaCantidad = changeset.get('unidadMedida.cantidad') || 0;
      let cantidadBaseFactor = changeset.get('movimiento').get('deha') === 'D' ? 1 : -1; // WARNING: Este valor se sobre escribe en el backend para asegurar la consistencia.

      changeset.set('cantidadBase', changeset.get('cantidad') * unidadMedidaCantidad * cantidadBaseFactor);

      if (indexCtrl) {
        indexCtrl.set('existencia', indexCtrl.existenciaBase / (unidadMedidaCantidad || 1));
      }
    },

    onChangesetCantidadUpdated(changeset) {
      (true && !((0, _isChangeset.default)(changeset)) && Ember.assert('Argument 1 must be an instance of Changeset', (0, _isChangeset.default)(changeset)));
      let totalNeto = changeset.get('cantidad') * changeset.get('precioUnitario') || 0;
      let unidadMedidaCantidad = changeset.get('unidadMedida.cantidad') || 0;
      let cantidadBaseFactor = changeset.get('movimiento').get('deha') === 'D' ? 1 : -1;

      if (changeset.get('movimiento').get('tipoMovimiento').get('tipomov') === 'AJUSTE') {
        if (!changeset.get('cantidad')) {
          totalNeto = changeset.get('precioUnitario');
        }
      }

      Ember.setProperties(changeset, {
        subtotal: totalNeto,
        valorNeto: totalNeto,
        total: totalNeto,
        valorNetoMe: changeset.get('tasac') ? (0, _format.round)(totalNeto / changeset.get('tasac')) : 0,
        // WARNING: Este valor se sobre escribe en el backend para asegurar la consistencia.
        cantidadBase: changeset.get('cantidad') * unidadMedidaCantidad * cantidadBaseFactor
      });
    },

    createMovDetUbicacionChangeset(movDetUbicacion) {
      return new _emberChangeset.default(movDetUbicacion, (0, _emberChangesetValidations.default)(_karMovDetUbicacion.default), _karMovDetUbicacion.default);
    },

    createMovDetUbicacionDestinoChangeset(movDetUbicacion) {
      return new _emberChangeset.default(movDetUbicacion, (0, _emberChangesetValidations.default)(_karMovDetUbicacionDestino.default), _karMovDetUbicacionDestino.default);
    },

    createMovDetUbicacionesChangesets(tiposUbicaciones, movDet) {
      return tiposUbicaciones.map(tipoUbicacion => {
        let movDetUbicacion = movDet.get('ubicaciones').findBy('tipoUbicacion.id', tipoUbicacion.id);

        if (!movDetUbicacion) {
          movDetUbicacion = this.store.createRecord('kar-mov-det-ubicacion', {
            movimientoDetalle: movDet,
            tipoUbicacion
          });
        }

        return this.createMovDetUbicacionChangeset(movDetUbicacion);
      });
    },

    updateBodegaUbicaciones(bodega, ubicacionesMeta) {
      // Resetear las opciones de cada tipo de ubicación.
      Object.values(ubicacionesMeta).forEach(ubicacionMeta => {
        Ember.set(ubicacionMeta, 'ubicaciones', Ember.A([]));
      });

      if (!bodega || !bodega.get('id')) {
        return Ember.RSVP.resolve(true);
      }

      return Ember.RSVP.hash({
        bodegaUbicaciones: this.store.query('kar-articulo-ubicacion', {
          filter: {
            bodega: bodega.get('id')
          }
        })
      }).then(({
        bodegaUbicaciones
      }) => {
        bodegaUbicaciones.forEach(bodegaUbicacion => {
          ubicacionesMeta[bodegaUbicacion.get('tipoUbicacion.id')].ubicaciones.pushObject(bodegaUbicacion);
        });
        return Ember.RSVP.resolve({
          bodegaUbicaciones
        });
      });
    },

    updateUbicacionSubDivisiones(tipoUbicacion, articuloUbicacion, ubicacionesMeta) {
      // Resetear las opciones de los tipos de ubicación que dependen del tipo
      // actualizado (sub clasificaciones, en principio debería ser solo uno).
      Object.values(ubicacionesMeta).forEach(ubicacionMeta => {
        if (ubicacionMeta.tipoUbicacion.get('tipoUbicacionSuperior.id') === tipoUbicacion.get('id')) {
          Ember.set(ubicacionMeta, 'ubicaciones', Ember.A([]));
        }
      });

      if (!tipoUbicacion.get('tipoUbicacionInferior.id') || !articuloUbicacion) {
        return Ember.RSVP.resolve(true);
      }

      return Ember.RSVP.hash({
        ubicacionSubdivisiones: this.store.query('kar-articulo-ubicacion', {
          filter: {
            ubicacionSuperior: articuloUbicacion.id
          }
        })
      }).then(({
        ubicacionSubdivisiones
      }) => {
        ubicacionSubdivisiones.forEach(articuloUbicacion => {
          ubicacionesMeta[articuloUbicacion.get('tipoUbicacion.id')].ubicaciones.pushObject(articuloUbicacion);
        });
        return Ember.RSVP.resolve({
          ubicacionSubdivisiones
        });
      });
    },

    _findUbicacionLastChildFromNode(ubicaciones, ubicacionInicio) {
      let ubicacionHija = ubicaciones.findBy('tipoUbicacion.id', ubicacionInicio.get('tipoUbicacion').get('tipoUbicacionInferior.id'));

      if (!ubicacionHija) {
        return ubicacionInicio;
      }

      return this._findUbicacionLastChildFromNode(ubicaciones, ubicacionHija);
    },

    findUbicacionUltimoNivel(ubicaciones) {
      let ubicacionPrincipal = ubicaciones.findBy('tipoUbicacion.esPrincipal', true);

      if (!ubicacionPrincipal) {
        return null;
      }

      return this._findUbicacionLastChildFromNode(ubicaciones, ubicacionPrincipal);
    },

    _destroyUbicacionesParentsRecursively(ubicaciones, ubicacionInicio) {
      let ubicacionPapa = ubicaciones.findBy('tipoUbicacion.id', ubicacionInicio.get('tipoUbicacion').get('tipoUbicacionSuperior.id'));
      ubicacionInicio.rollbackAttributes();

      if (ubicacionPapa) {
        this._destroyUbicacionesParentsRecursively(ubicaciones, ubicacionPapa);
      }
    },

    /**
     * Cuando se cancela la creación de un registro que tiene ubicaciones (kar-movdet),
     * es necesario eliminar los registros de ubicaciones dinámicas. Si se recorre el
     * array y se aplica el rollbackAttributes() sobre cada registro, por alguna razón
     * el forEach obtiene elementos indefinidos. La solución a este problema es eliminar
     * los registros desde los hijos hasta la ubicación principal.
     */
    destroyUnsavedUbicaciones(ubicaciones) {
      let ubicacionUltimoNivel = this.findUbicacionUltimoNivel(ubicaciones);

      if (ubicacionUltimoNivel) {
        this._destroyUbicacionesParentsRecursively(ubicaciones, ubicacionUltimoNivel);
      }
    }

  });

  _exports.default = _default;
});