define("sirvo-web/routes/inventario/procesos/inventario-fisico/articulos/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    modelName: 'kar-inventario-fisico-articulo',
    indexRoute: 'inventario.procesos.inventario-fisico.articulos',
    routeRecordIdentifierDynamicSegment: 'articulo_id',
    validations: Ember.computed(function () {
      return {
        inventarioFisico: (0, _validators.validatePresence)({
          presence: true
        }),
        articulo: (0, _validators.validatePresence)({
          presence: true
        }),
        bodega: (0, _validators.validatePresence)({
          presence: true
        }),
        existencia: (0, _validators.validateNumber)({
          presence: true,
          gte: 0
        })
      };
    }),

    model()
    /*params, transition*/
    {
      return this._super(...arguments).then(modelData => {
        let record = modelData.record;
        return Ember.RSVP.all([record.get('articulo'), record.get('bodega'), record.get('inventarioFisicoConteo')]).then(() => {
          if (record.get('inventarioFisicoConteo.id')) {
            return modelData;
          }

          return Ember.RSVP.hash({
            conteoActivo: this.store.query('kar-inventario-fisico-conteo', {
              filter: {
                inventarioFisico: record.get('inventarioFisico.id'),
                page_number: 1,
                page_size: 1
              },
              sort: '-numero'
            })
          }).then(({
            conteoActivo
          }) => {
            record.set('inventarioFisicoConteo', conteoActivo.get('firstObject'));
            return modelData;
          });
        });
      });
    },

    createRecordInstance() {
      let parentData = this.modelFor(this.indexRoute);
      return this.store.createRecord(this.modelName, {
        inventarioFisico: parentData.inventario,
        bodega: parentData.inventario.get('bodega')
      });
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});