define("sirvo-web/controllers/inventario/procesos/inventario-fisico/articulos", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/models/kar-inventario-fisico-articulo"], function (_exports, _abstractModuleIndex, _karInventarioFisicoArticulo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    modelClass: _karInventarioFisicoArticulo.default,
    reload: true,
    editRoute: 'inventario.procesos.inventario-fisico.articulos.record',
    modelFilters: Ember.computed('model.inventario', function () {
      return [{
        selectedFilter: {
          filterName: 'inventarioFisico'
        },
        value: this.model.inventario.id
      }];
    }),
    columns: Ember.computed(function () {
      return [{
        label: 'Artículo',
        valuePath: 'articulo.codigoDescripcion',
        width: '250px'
      }, {
        label: 'Bodega',
        valuePath: 'bodega.codigoDescripcion',
        width: '200px'
      }, {
        label: 'Existencia',
        valuePath: 'existencia',
        width: '100px',
        sortable: false
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.editRecord.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }];
    }),

    reloadTable() {
      this.set('reload', true);
    },

    actions: {
      addRecord() {
        this.transitionToRoute(this.editRoute, 'new');
      },

      editRecord(record) {
        if (!this.model.inventario || !record || !record.id) {
          return;
        }

        this.transitionToRoute(this.editRoute, this.model.inventario.id, record.id);
      },

      delete(record) {
        if (!this.model.inventario || !record) {
          return;
        }

        if (this.model.inventario.get('cerrado')) {
          this.toast.error('Inventario cerrado.');
          return;
        }

        return this._super(...arguments);
      }

    }
  });

  _exports.default = _default;
});