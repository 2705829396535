define("sirvo-web/models/kar-mov", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    empresa: (0, _model.belongsTo)('empresa'),
    // keytraTran: attr('string'),
    // keyrefTran: attr('string'),
    keymov: (0, _model.attr)('string'),
    // keytra: attr('string'),
    // keyref: attr('string'),
    // keyaux: attr('string'),
    fecha: (0, _model.attr)('date'),
    // yearmes: attr('string'),
    correlativo: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    tipoMovimiento: (0, _model.belongsTo)('kar-tipomov'),
    deha: (0, _model.attr)('string'),
    serie: (0, _model.attr)('string'),
    numero: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    tipoDocumento: (0, _model.belongsTo)('kar-doc'),
    usacnt: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    valordocml: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    valorNeto: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    porcentajeIva: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    iva: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // tipoIva: attr('string'),
    grupo: (0, _model.belongsTo)('grupo'),
    proveedor: (0, _model.belongsTo)('gru-directorio'),
    // codvendedor: attr('string'),
    // keytraOrdenCompraRef: attr('string'),
    // keytraFactura: attr('string'),
    referencia: (0, _model.attr)('string'),
    // descripcion: attr('string'),
    observaciones: (0, _model.attr)('string'),
    // espropio: attr('number', { defaultValue: 0 }),
    // estado: attr('string'),
    // anulado: attr('number', { defaultValue: 0 }),
    // admHospital: attr('string'),
    // admPaciente: attr('string'),
    // admRegistro: attr('string'),
    // admServicio: attr('string'),
    // admDoctor: attr('string'),
    // admFechaCirugia: attr('date'),
    // admInstrumentista: attr('string'),
    // admCoduserEntrega: attr('string'),
    // admCoduserRecibe: attr('string'),
    vehiculo: (0, _model.attr)('string'),
    placa: (0, _model.attr)('string'),
    // responsable: attr('string'),
    piloto: (0, _model.attr)('string'),
    autorizadoPor: (0, _model.belongsTo)('gru-directorio'),
    recibidoPor: (0, _model.belongsTo)('gru-directorio'),
    // codusr: attr('string'),

    /* Computed properties */
    fechaFormatted: Ember.computed('fecha', function () {
      const momentDate = (0, _moment.default)(this.fecha);

      if (Ember.isNone(this.fecha) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    }),

    didLoad() {
      // Hack to avoid the current time set by Ember that affect comparisons.
      if (this.fecha instanceof Date) {
        this.fecha.setHours(0, 0, 0, 0);
      }
    }

  });

  _exports.default = _default;
});