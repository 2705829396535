define("sirvo-web/controllers/inventario/procesos/inventario-fisico/index/comparar", ["exports", "moment", "sirvo-web/utils/date"], function (_exports, _moment, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    request: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    indexRoute: 'inventario.procesos.inventario-fisico.index',
    years: (0, _date.lastYears)(5),
    currentYear: Ember.computed(function () {
      return new Date().getFullYear();
    }),
    changeset: null,
    actions: {
      onUpdateYear() {
        this.changeset.set('inventarioTeorico', null);
        this.loader.setLoading(true);
        this.request.get('kar/cierres-anio', {
          empresa: this.company.id,
          anio: this.currentYear
        }).then(inventariosTeoricos => {
          inventariosTeoricos.forEach(inventarioTeorico => {
            let momentDate = (0, _moment.default)(inventarioTeorico.fecha);
            Ember.setProperties(inventarioTeorico, {
              fechaFormatted: momentDate.isValid() ? momentDate.format('DD/MM/YYYY') : null
            });
          });
          Ember.setProperties(this.model, {
            inventariosTeoricos
          });
        }).finally(() => this.loader.setLoading(false));
      },

      submit() {
        return this.changeset.validate().then(() => {
          if (!this.changeset.get('isValid')) {
            return;
          }

          let data = {
            empresa: this.changeset.get('empresa.id'),
            inventarioFisico: this.changeset.get('inventarioFisico.id'),
            inventarioTeoricoFecha: this.changeset.get('inventarioTeorico.fecha'),
            soloLineasConDiferencia: this.changeset.get('soloLineasConDiferencia') ? 1 : 0
          };
          this.loader.setLoading(true);
          return this.request.get('kar/reportes/inventario-fisico-vs-inventario-teorico', data).then(() => {
            this.toast.success('Reporte generado exitosamente');
          }).finally(() => this.loader.setLoading(false));
        });
      },

      onClose() {
        this.transitionToRoute(this.indexRoute);
      }

    }
  });

  _exports.default = _default;
});