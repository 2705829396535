define("sirvo-web/controllers/inventario/movimientos/salidas/registro-detalle/articulo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    indexCtrl: Ember.inject.controller('inventario.movimientos.salidas.registro-detalle'),
    kardex: Ember.inject.service(),
    actions: {
      onClose() {
        this.transitionToRoute(this.parentRoute);
      },

      articuloUpdated() {
        this.kardex.onChangesetArticuloUpdated(this.changeset).then(articuloData => {
          this.setProperties(articuloData);
        });
      },

      bodegaUpdated() {
        // Si no hay ubicaciones dinámicas, la existencia se calcula a nivel de bodega.
        if (!this.ubicacionesChangesets.length) {
          this.kardex.onChangesetBodegaUpdated(this.changeset, this.indexCtrl);
          return;
        } // Resetear el valor seleccionado de cada ubicación.


        this.ubicacionesChangesets.forEach(ubicacionChangeset => {
          ubicacionChangeset.set('articuloUbicacion', null);
        });
        this.kardex.updateBodegaUbicaciones(this.changeset.get('bodega'), this.ubicacionesMeta);
      },

      unidadMedidaUpdated() {
        this.kardex.onChangesetUnidadMedidaUpdated(this.changeset, this.indexCtrl);
      },

      ubicacionUpdated(tipoUbicacion, articuloUbicacion) {
        this.kardex.resetChangesetCalculo(this.changeset); // Resetear el valor seleccionado de las ubicaciones dependientes.

        this.ubicacionesChangesets.forEach(ubicacionChangeset => {
          if (ubicacionChangeset.get('tipoUbicacion').get('tipoUbicacionSuperior.id') === tipoUbicacion.get('id')) {
            ubicacionChangeset.set('articuloUbicacion', null);
          }
        });
        this.kardex.updateUbicacionSubDivisiones(tipoUbicacion, articuloUbicacion, this.ubicacionesMeta).then(() => {
          let ubicacionUltimoNivel = this.kardex.findUbicacionUltimoNivel(this.ubicacionesChangesets);

          if (ubicacionUltimoNivel) {
            this.kardex.onArticuloUbicacionUpdated(this.changeset, ubicacionUltimoNivel, this.indexCtrl);
          }
        });
      }

    }
  });

  _exports.default = _default;
});