define("sirvo-web/routes/admon/grupos/directorios/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _hasTabs, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, {
    company: Ember.computed.readOnly('currentCompany.company'),
    modelName: 'gru-directorio',
    indexRoute: 'admon.grupos.directorios',
    defaultTabSubRoute: 'generales',
    breadcrumbsRoute: Ember.computed('recordId', function () {
      return [{
        name: 'Directorios',
        route: 'admon.grupos.directorios'
      }, {
        name: 'Editar',
        route: `admon.grupos.directorios.record.${this.defaultTabSubRoute}`,
        queryParam: this.recordId
      }];
    }),
    relatedModelsToQuery: Ember.computed(function () {
      let modelsToQuery = [{
        name: 'paises',
        modelName: 'com-pais'
      }, {
        name: 'departamentos',
        modelName: 'com-codigo-postal-depto'
      }, {
        name: 'municipios',
        modelName: 'com-codigo-postal-muni'
      }, {
        name: 'zonas',
        modelName: 'com-codigo-postal-zona'
      }];
      return modelsToQuery;
    }),
    validations: Ember.computed(function () {
      return {
        coddirectorio: (0, _validators.validatePresence)({
          presence: true
        }),
        razonsocial: (0, _validators.validatePresence)({
          presence: true
        }),
        pais: (0, _validators.validatePresence)({
          presence: true
        }),
        direccion: (0, _validators.validatePresence)({
          presence: true
        }),
        telefonooficina: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    createRecordInstance() {
      return this.store.createRecord(this.modelName, {
        grupo: this.company.get('grupo')
      });
    }

  });

  _exports.default = _default;
});