define("sirvo-web/controllers/inventario/movimientos/ingresos/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators", "sirvo-web/validations/kar-movdet", "sirvo-web/utils/format"], function (_exports, _abstractModuleRecord, _emberChangeset, _emberChangesetValidations, _validators, _karMovdet, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    kardex: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    indexCtrl: Ember.inject.controller('inventario.movimientos.ingresos'),
    company: Ember.computed.readOnly('currentCompany.company'),
    karParameters: Ember.computed.readOnly('currentCompany.karParameters'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    indexRoute: null,
    items: null,
    lineaModel: null,
    lineaChangeset: null,
    karMovDetValidations: _karMovdet.default,
    registroCerrado: Ember.computed('record.fecha', 'fechaCierre', function () {
      if (!this.fechaCierre || !this.record.get('fecha')) {
        return false;
      }

      return this.fechaCierre >= this.record.get('fecha');
    }),
    proveedorActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editProveedor.bind(this)
      }];
    }),
    articuloActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editArticulo.bind(this)
      }];
    }),
    columns: Ember.computed(function () {
      return [{
        label: 'Articulo',
        valuePath: 'articulo.codarticulo',
        filtrable: true,
        filterName: 'articulo',
        sortable: false,
        width: '100px'
      }, {
        label: 'Descripción',
        valuePath: 'articulo.descripcion',
        filtrable: true,
        filterName: 'articulo.descripcion',
        sortable: false,
        width: '100px'
      }, {
        label: 'Cantidad',
        valuePath: 'cantidad',
        filtrable: true,
        filterName: 'cantidad',
        sortable: false,
        width: '100px'
      }, {
        label: 'U/Medida',
        valuePath: 'unidadMedida.codmedida',
        filtrable: true,
        filterName: 'unidadMedida.codmedida',
        sortable: false,
        width: '100px'
      }, {
        label: 'Precio',
        valuePath: 'precioUnitario',
        filtrable: true,
        filterName: 'precioUnitario',
        sortable: false,
        width: '100px'
      }, {
        label: 'Costo',
        valuePath: 'total',
        filtrable: true,
        filterName: 'total',
        sortable: false,
        width: '100px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.deleteItem.bind(this)
          }
        }
      }];
    }),

    createNewLinewChangeset() {
      this.set('lineaModel', this.store.createRecord('kar-movdet', {
        empresa: this.company,
        movimiento: this.record,
        linea: this.items.length + 1
      }));
      this.set('lineaChangeset', new _emberChangeset.default(this.lineaModel, (0, _emberChangesetValidations.default)(_karMovdet.default), _karMovdet.default));
      this.set('ubicacionesChangesets', this.kardex.createMovDetUbicacionesChangesets(this.tiposUbicaciones, this.lineaModel));
    },

    saveItemsSequentially() {
      return this.items.filterBy('hasDirtyAttributes').reduce((prevPromise, currentItem) => prevPromise.then(() => currentItem.save()), Ember.RSVP.resolve(true)).catch(error => {
        this.toast.error('Ocurrió un error al guardar las líneas de detalle.');

        if (Array.isArray(error.errors)) {
          error.errors.forEach(err => err.message && this.toast.error(err.message));
        }

        throw error;
      });
    },

    actions: {
      onClose() {
        this.record.rollbackAttributes();
        this.ubicacionesChangesets.forEach(changeset => changeset.rollback()); // Eliminar los registros de ubicación del ítem actual.

        this.kardex.destroyUnsavedUbicaciones(this.lineaModel.get('ubicaciones')); // Es necesario que esto ocurra después de eliminar las ubicaciones.

        this.lineaModel.rollbackAttributes(); // Eliminar las ubicaciones de las líneas que aún no se han guardado.

        this.items.filterBy('isNew').forEach(linea => {
          this.kardex.destroyUnsavedUbicaciones(linea.get('ubicaciones'));
        }); // Eliminar líneas no guardadas.

        this.items.filterBy('isNew').forEach(linea => linea.rollbackAttributes());
        this.transitionToRoute(this.indexRoute);
      },

      deleteItem(item) {
        if (this.fechaCierre >= this.record.get('fecha')) {
          this.toast.error('Registro solo de lectura por cierre de inventario.');
          return;
        }

        return item.destroyRecord().then(() => {
          this.toast.success('Línea eliminada correctamente.');
          this.set('items', this.items.without(item));
        }).catch(error => {
          this.toast.error('No se pudo eliminar la línea de detalle.');

          if (Array.isArray(error.errors)) {
            error.errors.forEach(err => err.message && this.toast.error(err.message));
          }

          throw error;
        }).catch(error => this.errorHandler.handleBackendErrors(error));
      },

      cancelAddingItem() {
        this.lineaChangeset.rollback();
        this.ubicacionesChangesets.forEach(changeset => changeset.rollback());
      },

      addItem() {
        if (this.lineaChangeset.get('articulo.usaLote')) {
          this.karMovDetValidations.lote = (0, _validators.validatePresence)(true);
        } else {
          delete this.karMovDetValidations.lote;
          delete this.lineaChangeset._errors.lote;
        }

        if (this.lineaChangeset.get('articulo.usaFechaVencimiento')) {
          this.karMovDetValidations.fechaVencimiento = (0, _validators.validatePresence)(true);
        } else {
          delete this.karMovDetValidations.fechaVencimiento;
          delete this.lineaChangeset._errors.fechaVencimiento;
        }

        return Ember.RSVP.all([this.lineaChangeset.validate(), ...this.ubicacionesChangesets.map(changeset => changeset.validate())]).then(() => {
          if (this.lineaChangeset.get('isInvalid')) {
            this.toast.error('Hay un problema de validación del artículo.');
            return;
          }

          let ubicacionesChangesetsValid = this.ubicacionesChangesets.reduce((prevIsValid, changeset) => prevIsValid && changeset.get('isValid'), true);

          if (!ubicacionesChangesetsValid) {
            this.toast.error('Hay un problema con las ubicaciones del artículo.');
            return;
          }

          this.lineaChangeset.execute();
          this.items.pushObject(this.lineaModel);
          this.ubicacionesChangesets.forEach(changeset => changeset.execute());
          this.createNewLinewChangeset();
          this.toast.info('Artículo agregado correctamente. No olvide guardar sus cambios.');
        });
      },

      updateValor(changeset, name, value = 0) {
        changeset.set(name, parseFloat(value));
        let precioVenta = this.lineaChangeset.get('precioVenta');
        let unidadMedidaCantidad = this.lineaChangeset.get('unidadMedida.cantidad') || 0;
        let tasaCambio = this.lineaChangeset.get('tasac');
        let total = precioVenta;
        let iva = 0;

        if (this.lineaChangeset.get('porcentajeIva')) {
          let porcentajeIva = this.lineaChangeset.get('porcentajeIva') / 100;
          total = (0, _format.round)(precioVenta / (1 + porcentajeIva));
          iva = precioVenta - total;
        }

        Ember.setProperties(this.lineaChangeset, {
          total,
          iva,
          subtotal: total,
          valorNeto: total,
          valorNetoMe: tasaCambio ? (0, _format.round)(total / tasaCambio) : 0,
          precioUnitario: (0, _format.round)(total / (this.lineaChangeset.get('cantidad') || 1)),
          // WARNING: Este valor se sobre escribe en el backend para asegurar la consistencia.
          cantidadBase: this.lineaChangeset.get('cantidad') * unidadMedidaCantidad
        });
      },

      serializeArticulo(articulo) {
        return articulo ? articulo.get('codigoDescripcion') : null;
      },

      serializeBodega(bodega) {
        return bodega ? bodega.get('codigoDescripcion') : null;
      },

      serializeUnidadMedida(uMedida) {
        return uMedida ? uMedida.get('codmedida') : null;
      },

      submit() {
        if (!this.items.length) {
          this.toast.error('Debe guardar al menos un detalle de ingreso.');
          return;
        }

        return this.changeset.validate().then(() => {
          if (this.changeset.get('isInvalid')) {
            this.toast.error('Hay un problema de validación del movimiento.');
            return;
          }

          let valordocml = this.items.mapBy('total').reduce((sum, value) => sum + value);
          this.changeset.set('valordocml', valordocml);
          this.changeset.set('valorNeto', valordocml);
          this.loader.setLoading(true);
          return this.changeset.save().then(() => this.saveItemsSequentially()).then(() => {
            this.toast.success('Registro almacenado correctamente.');
            this.indexCtrl.set('reload', true);
            this.transitionToRoute(`${this.routeName}`, this.record.id);
          }).catch(error => {
            this.toast.error('Ocurrió un error al guardar el registro.');
            throw error;
          }).finally(() => this.loader.setLoading(false));
        });
      },

      editProveedor() {
        this.transitionToRoute(`${this.routeName}.proveedor`);
      },

      editArticulo() {
        this.transitionToRoute(`${this.routeName}.articulo`);
      }

    }
  });

  _exports.default = _default;
});