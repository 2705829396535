define("sirvo-web/routes/inventario/tablas/articulos/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _hasTabs, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, {
    company: Ember.computed.readOnly('currentCompany.company'),
    karParameters: Ember.computed.readOnly('currentCompany.karParameters'),
    modelName: 'kar-articulo',
    indexRoute: 'inventario.tablas.articulos',
    defaultTabSubRoute: 'datos-generales',
    relatedModelsToQuery: Ember.computed('company', function () {
      let modelsToQuery = [{
        name: 'tiposArticulos',
        modelName: 'kar-tipo-articulo',
        queryParams: {
          filter: {
            empresa: this.company.get('id')
          }
        }
      }];

      if (!this.isNew) {
        modelsToQuery.push({
          name: 'unidadesMedida',
          modelName: 'kar-tabla-medida',
          queryParams: {
            filter: {
              articulo: this.recordId
            }
          }
        });
      }

      return modelsToQuery;
    }),
    validations: Ember.computed(function () {
      return {
        empresa: (0, _validators.validatePresence)({
          presence: true
        }),
        correlativo: (0, _validators.validatePresence)({
          presence: true
        }),
        tipoArticulo: (0, _validators.validatePresence)({
          presence: true
        }),
        codmedidaBase: (0, _validators.validatePresence)({
          presence: true
        }),
        descripcion: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    createRecordInstance() {
      return this.store.createRecord(this.modelName, {
        empresa: this.company
      });
    },

    setupController()
    /*controller, model*/
    {
      this._super(...arguments);

      if (!this.karParameters || !this.karParameters.get('codigoArticulosGen')) {
        this.set('validations', { ...this.validations,
          correlativo: (0, _validators.validatePresence)({
            presence: true
          })
        });
      } else {
        delete this.validations['correlativo'];
      }
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});