define("sirvo-web/controllers/inventario/movimientos/ingresos/existencia-inicial/movimiento", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    usaCorrelativoAutomatico: Ember.computed('changeset.tipoDocumento', function () {
      if (!this.changeset.get('tipoDocumento')) {
        return false;
      }

      return this.changeset.get('tipoDocumento').get('correlativoAutomatico');
    }),
    registroCerrado: Ember.computed('record.fecha', 'fechaCierre', function () {
      if (!this.fechaCierre || !this.record.get('fecha')) {
        return false;
      }

      return this.fechaCierre >= this.record.get('fecha');
    }),
    tipoDocumentoActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editTipoDocumento.bind(this)
      }];
    }),
    proveedorActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editProveedor.bind(this)
      }];
    }),
    autorizadoPorActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editAutorizacion.bind(this)
      }];
    }),
    actions: {
      editTipoDocumento() {
        this.transitionToRoute(`${this.routeName}.tipo-doc`);
      },

      editProveedor() {
        this.transitionToRoute(`${this.routeName}.proveedor`);
      },

      editAutorizacion() {
        this.transitionToRoute(`${this.routeName}.autorizacion`);
      }

    }
  });

  _exports.default = _default;
});