define("sirvo-web/templates/inventario/tablas/articulos/record/configuracion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V+Z3Nv3N",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"extended-form-for\",[[21,0,[\"changeset\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col col-sm-6\"],[8],[0,\"\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"debaja\",\"Dar de baja\"],[[\"labelSide\"],[\"right\"]]],false],[0,\"\\n\\n\"],[4,\"if\",[[21,0,[\"changeset\",\"debaja\"]]],null,{\"statements\":[[0,\"        \"],[1,[26,\"component\",[[21,1,[\"ace-datetimepicker\"]],\"fechaDebaja\",\"Fecha de baja\"],[[\"date\",\"format\",\"pickTime\",\"forceDateOutput\",\"showIcon\",\"placeholder\"],[[21,0,[\"changeset\",\"fechaDebaja\"]],\"DD/MM/YYYY\",false,true,false,\"dd/mm/yyyy\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col col-sm-6\"],[8],[0,\"\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"usaLote\",\"Usa control por lote\"],[[\"labelSide\"],[\"right\"]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"usaFechaVencimiento\",\"Valida fecha de vencimiento\"],[[\"labelSide\"],[\"right\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/tablas/articulos/record/configuracion.hbs"
    }
  });

  _exports.default = _default;
});