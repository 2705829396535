define("sirvo-web/services/error-handler", ["exports", "ember-data", "ember-changeset/utils/is-changeset"], function (_exports, _emberData, _isChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    InvalidError
  } = _emberData.default;

  var _default = Ember.Service.extend({
    toast: Ember.inject.service(),

    handleBackendErrors(errorRes, errorMsg) {
      if (typeof errorMsg === 'string') {
        this.toast.error(errorMsg);
      }

      let headers = errorRes.headers && errorRes.headers.map || errorRes.headers;

      if (headers && headers['content-type'].indexOf('application/vnd.api+json') === 0) {
        return errorRes.json().then(error => {
          if (error.errors && Array.isArray(error.errors)) {
            error.errors.forEach(err => err.message && this.toast.error(err.message));
          }

          throw errorRes;
        });
      }

      throw errorRes;
    },

    handleSaveErrors(errorRes, entity, errorMsg) {
      // Ignore errors of type TransitionAborted.
      if (errorRes.name && errorRes.name === 'TransitionAborted') {
        return;
      } // TODO: Fix to get the errors correctly and add them to the changeset.


      if (errorRes instanceof InvalidError && (0, _isChangeset.default)(entity)) {
        entity.get('_content').get('errors').forEach(error => {
          if ('base' === error.attribute) {
            return;
          }

          entity.addError(error.attribute, [error.message]);
        });
      }

      if (Array.isArray(errorRes.errors)) {
        errorRes.errors.forEach(err => {
          // Looks like a validation error?
          if (err.detail && err.source && err.source.pointer) {
            let attribute = err.source.pointer.split('/').lastObject;

            if ((0, _isChangeset.default)(entity)) {
              entity.addError(attribute, [err.detail]);
              entity.get('_content').rollbackAttributes();
            } else {
              this.toast.error(`${attribute}: ${err.detail}`);
            }
          } else {
            this.toast.error(err.title || err.message || 'Ocurrió un error.');
          }
        });
      } else {
        this.toast.error(errorRes.message || 'Ocurrió un error.');
      }

      if (typeof errorMsg === 'string') {
        this.toast.error(errorMsg);
      }

      throw errorRes;
    }

  });

  _exports.default = _default;
});