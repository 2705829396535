define("sirvo-web/routes/inventario/movimientos/salidas/traslado/registro/articulo", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    kardex: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),

    model() {
      let superData = this._super(...arguments);

      if (!superData.record.get('isNew')) {
        this.toast.error('No puede modificar un registro ya persistido.');
        return this.transitionTo(this.parentRoute);
      }

      if (this.fechaCierre >= superData.record.get('movimiento').get('fecha')) {
        this.toast.error('Registro solo de lectura por cierre de inventario.');
        return this.transitionTo(this.parentRoute);
      }

      let changeset = this.controllerFor(this.parentRoute).changeset;
      let queries = {
        articulos: this.store.query('kar-articulo', {
          filter: {
            empresa: this.company.id,
            debaja: 0
          },
          sort: 'descripcion'
        }),
        bodegas: this.store.query('kar-bodega', {
          filter: {
            empresa: this.company.id
          }
        }),
        unidadesMedida: Ember.A([])
      };

      if (changeset && changeset.get('articulo.id')) {
        queries.unidadesMedida = this.store.query('kar-tabla-medida', {
          filter: {
            articulo: changeset.get('articulo.id'),
            venta: 1
          }
        });
      } // WARNING: Using the Ember merge() function gives error.
      // ERROR: You must use set() to set the `articulos` property.


      return Ember.RSVP.hash(queries).then(routeData => ({ ...superData,
        ...routeData
      }));
    },

    setupController(controller, model) {
      this._super(controller, model);

      let parenController = this.controllerFor(this.parentRoute);
      controller.set('ubicacionesChangesets', parenController.get('ubicacionesChangesets'));
      controller.set('ubicacionesMeta', {});
      model.tiposUbicaciones.forEach(tipoUbicacion => {
        controller.ubicacionesMeta[tipoUbicacion.id] = {
          tipoUbicacion,
          ubicaciones: Ember.A([])
        };
      });

      if (controller.changeset.get('bodega.id')) {
        this.kardex.updateBodegaUbicaciones(controller.changeset.get('bodega'), controller.ubicacionesMeta);
        controller.ubicacionesChangesets.forEach(ubicacionChangeset => {
          this.kardex.updateUbicacionSubDivisiones(ubicacionChangeset.get('tipoUbicacion'), ubicacionChangeset.get('articuloUbicacion'), controller.ubicacionesMeta);
        });
      }
    }

  });

  _exports.default = _default;
});