define("sirvo-web/transforms/custom-date", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize(serialized) {
      if (typeof serialized === 'string' && serialized.trim()) {
        return (0, _moment.default)(serialized, 'YYYY-MM-DD HH:mm:ss').toDate();
      }

      return null;
    },

    serialize(date) {
      if (date instanceof Date && !isNaN(date)) {
        return (0, _moment.default)(date).format('YYYY-MM-DD HH:mm:ss');
      } else {
        return null;
      }
    }

  });

  _exports.default = _default;
});