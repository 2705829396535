define("sirvo-web/models/kar-inventario-fisico", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    empresa: (0, _model.belongsTo)('empresa'),
    bodega: (0, _model.belongsTo)('kar-bodega'),
    fecha: (0, _model.attr)('date'),
    correlativo: (0, _model.attr)('number'),
    observaciones: (0, _model.attr)('string'),
    cerrado: (0, _model.attr)('boolean'),
    // creusr: attr('string'),
    // fecusr: attr('date'),
    // codusr: attr('string'),
    fechaFormatted: Ember.computed('fecha', function () {
      const momentDate = (0, _moment.default)(this.fecha);

      if (!this.fecha || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    })
  });

  _exports.default = _default;
});