define("sirvo-web/validations/kar-movdet-ajuste", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    empresa: (0, _validators.validatePresence)(true),
    movimiento: (0, _validators.validatePresence)(true),
    articulo: (0, _validators.validatePresence)(true),
    bodega: (0, _validators.validatePresence)(true),
    unidadMedida: (0, _validators.validatePresence)(true),
    cantidad: (0, _validators.validateNumber)({
      gte: 0
    }),
    total: (0, _validators.validateNumber)({
      gte: 0
    })
  };
  _exports.default = _default;
});