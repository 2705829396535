define("sirvo-web/controllers/inventario/procesos/inventario-fisico/articulos/record/bodega", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      onClose() {
        this.transitionToRoute(this.parentRoute);
      }

    }
  });

  _exports.default = _default;
});