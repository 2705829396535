define("sirvo-web/templates/inventario/tablas/articulos/record/unidades-medida", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OkLPV1QB",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n\"],[4,\"extended-form-for\",[[21,0,[\"unidadMedidaChangeset\"]]],null,{\"statements\":[[0,\"      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"codmedida\",\"Código\"],[[\"placeholder\",\"labelSize\",\"widgetSize\",\"disabled\"],[\"Código\",\"sm-6\",\"sm-6\",[21,0,[\"isUnidadBase\"]]]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-number-field\"]],\"cantidad\",\"Equivalencia\"],[[\"placeholder\",\"labelSize\",\"widgetSize\",\"disabled\"],[\"Equivalencia\",\"sm-6\",\"sm-6\",[21,0,[\"isUnidadBase\"]]]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"compra\",\"Unidad de Compra\"],[[\"labelSize\",\"widgetSize\",\"disabled\"],[\"sm-6\",\"sm-6\",[21,0,[\"isUnidadBase\"]]]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"venta\",\"Unidad de Venta\"],[[\"labelSize\",\"widgetSize\",\"disabled\"],[\"sm-6\",\"sm-6\",[21,0,[\"isUnidadBase\"]]]]],false],[0,\"\\n\\n      \"],[1,[26,\"forms/controls/ace-async-save-button\",null,[[\"classNames\",\"onClick\"],[\"pull-right\",[26,\"action\",[[21,0,[]],\"saveUnidadMedida\"],null]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n    \"],[1,[26,\"tables/simple-table\",null,[[\"model\",\"columns\",\"tableClassNames\",\"height\",\"fixed\"],[[26,\"filter\",[[26,\"action\",[[21,0,[]],\"isValid\"],null],[21,0,[\"record\",\"unidadesMedida\"]]],null],[21,0,[\"unidadesMedidaColumns\"]],\"ember-table thead-gray table-sm\",\"20vh\",true]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/tablas/articulos/record/unidades-medida.hbs"
    }
  });

  _exports.default = _default;
});