define("sirvo-web/models/kar-movdet", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    empresa: (0, _model.belongsTo)('empresa'),
    estado: (0, _model.attr)('string'),
    // keymov: attr('string'),
    movimiento: (0, _model.belongsTo)('kar-mov'),
    keymovdet: (0, _model.attr)('string'),
    // keyref: attr('string'),
    // keyaux: attr('string'),
    // keyenvio: attr('string'),
    // idpedido: attr('number', { defaultValue: 0 }),
    // idfactura: attr('number', { defaultValue: 0 }),
    // iddevolucion: attr('number', { defaultValue: 0 }),
    // idbatchproduccion: attr('number', { defaultValue: 0 }),
    // idfacturaManufactura: attr('number'),
    linea: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    bodega: (0, _model.belongsTo)('kar-bodega'),
    bodegaDestino: (0, _model.belongsTo)('kar-bodega'),
    ubicacionesDestino: (0, _model.attr)('string'),
    articulo: (0, _model.belongsTo)('kar-articulo'),
    unidadMedida: (0, _model.belongsTo)('kar-tabla-medida'),
    // nommedida: attr('string'),
    cantidad: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    cantidadBase: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    cantidadVenta: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // cantidadMedida1: attr('number', { defaultValue: 0 }),
    // cantidadMedida2: attr('number', { defaultValue: 0 }),
    // cantidadMedida3: attr('number', { defaultValue: 0 }),
    // nombreBulto: attr('string'),
    cantidadDeBultos: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    contenidoPorBulto: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    cantidadUnidadesSueltasPorBulto: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    descripcion: (0, _model.attr)('string'),
    precioUnitario: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    subtotal: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    valorNeto: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    valorNetoMe: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    precioVenta: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // dai: attr('number', { defaultValue: 0 }),
    // Not persisted
    porcentajeIva: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    iva: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // descuento: attr('number', { defaultValue: 0 }),
    total: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    tasac: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // keytraReq: attr('string'),
    // lineaReq: attr('number', { defaultValue: 0 }),
    // recibidoReq: attr('number', { defaultValue: 0 }),
    // coduserRecibido: attr('string'),
    fechaVencimiento: (0, _model.attr)('date'),
    // diajuliano: attr('string'),
    // fechaRecibido: attr('date'),
    lote: (0, _model.attr)('string'),
    // keytraOrden: attr('string'),
    // lineaOrden: attr('number', { defaultValue: 0 }),
    // porcentajeGanancia: attr('number', { defaultValue: 0 }),
    // porcentajeDescuento: attr('number', { defaultValue: 0 }),
    // usaCostoCero: attr('number', { defaultValue: 0 }),
    // keyCierreMensual: attr('string'),
    // avisos: attr('string'),
    // referencia: attr('string'),
    // cantidadFac: attr('number', { defaultValue: 0 }),
    // codarticuloFac: attr('string'),
    // cantidadVentaTemp: attr('number', { defaultValue: 0 }),
    // precioUnitarioTemp: attr('number', { defaultValue: 0 }),
    // precioVentaTemp: attr('number', { defaultValue: 0 }),
    observa: (0, _model.attr)('string'),
    // costoFacturado: attr('number', { defaultValue: 0 }),
    // fechaCostoFacturado: attr('date'),
    // temperatura: attr('number', { defaultValue: 0 }),
    // medidaEnCentimetros: attr('number', { defaultValue: 0 }),
    ubicacionEstante: (0, _model.attr)('string'),
    ubicacionCasilla: (0, _model.attr)('string'),
    cuenta: (0, _model.belongsTo)('emp-nomenclatura'),
    centroCosto: (0, _model.belongsTo)('emp-cc'),
    centroIntegracion: (0, _model.belongsTo)('emp-ci'),
    // creusr: attr('string'),
    // fecusr: attr('date'),
    // codusr: attr('string'),
    detalles: (0, _model.hasMany)('kar-movdet-det'),
    ubicaciones: (0, _model.hasMany)('kar-mov-det-ubicacion'),

    save(options) {
      if (this.get('isDeleted')) {
        return this._super(options);
      }

      let unsavedUbicaciones = this.ubicaciones.filter(ubicacion => ubicacion.get('isNew') || ubicacion.get('hasDirtyAttributes'));
      return this._internalModel.save(options).then(() => Ember.RSVP.all(unsavedUbicaciones.map(ubicacion => ubicacion.save()))).then(() => {
        return this;
      });
    }

  });

  _exports.default = _default;
});