define("sirvo-web/controllers/inventario/movimientos/ingresos/existencia-inicial/registro", ["exports", "servir-ember-utilities/controllers/abstract-module-record", "ember-changeset-validations/validators", "sirvo-web/utils/format"], function (_exports, _abstractModuleRecord, _validators, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    kardex: Ember.inject.service(),
    karParameters: Ember.computed.readOnly('currentCompany.karParameters'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    movimiento: null,
    bodegas: null,
    unidadesMedida: null,
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),
    registroCerrado: Ember.computed('movimiento.fecha', 'fechaCierre', function () {
      if (!this.fechaCierre || !this.movimiento.get('fecha')) {
        return false;
      }

      return this.fechaCierre >= this.movimiento.get('fecha');
    }),
    articuloActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editArticulo.bind(this)
      }];
    }),
    bodegaActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editArticulo.bind(this)
      }];
    }),
    medidaActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editArticulo.bind(this)
      }];
    }),

    submit() {
      // TODO: Find a better way to get the current route instance.
      let route = this.target.currentState.routerJs.getHandler(this.routeName);

      if (this.changeset.get('articulo.usaLote')) {
        route.validations.lote = (0, _validators.validatePresence)(true);
      } else {
        delete route.validations.lote;
        delete this.changeset._errors.lote;
      }

      let superRef = this._super.bind(this);

      return Ember.RSVP.all([this.changeset.validate(), ...this.ubicacionesChangesets.map(changeset => changeset.validate())]).then(() => {
        if (this.changeset.get('isInvalid')) {
          this.toast.error('Hay un problema de validación con el formulario.');
          return;
        }

        let ubicacionesChangesetsValid = this.ubicacionesChangesets.reduce((prevIsValid, changeset) => prevIsValid && changeset.get('isValid'), true);

        if (!ubicacionesChangesetsValid) {
          this.toast.error('Hay un problema con las ubicaciones del artículo.');
          return;
        }

        this.ubicacionesChangesets.forEach(changeset => changeset.execute());
        return superRef();
      });
    },

    updateDependentValues() {
      Ember.setProperties(this.changeset, {
        subtotal: this.changeset.get('total'),
        valorNeto: this.changeset.get('total'),
        valorNetoMe: this.changeset.get('tasac') ? (0, _format.round)(this.changeset.get('total') / this.changeset.get('tasac')) : 0
      });
    },

    discardChanges() {
      this.changeset.rollback();
      this.ubicacionesChangesets.forEach(changeset => changeset.rollback()); // Eliminar los registros de ubicación.

      this.kardex.destroyUnsavedUbicaciones(this.record.get('ubicaciones')); // Es necesario que esto ocurra después de eliminar las ubicaciones.

      this.record.rollbackAttributes();
    },

    actions: {
      updateCantidad(changeset, properyName, value) {
        changeset.set(properyName, parseFloat(value) || 0);
        this.kardex.onChangesetCantidadUpdated(changeset);
      },

      updateCosto(changeset, properyName, value) {
        changeset.set(properyName, parseFloat(value) || 0);

        if (properyName === 'precioUnitario') {
          changeset.set('total', (changeset.get('cantidad') || 1) * changeset.get('precioUnitario'));
        } else {
          changeset.set('precioUnitario', changeset.get('total') / (changeset.get('cantidad') || 1));
        }

        this.updateDependentValues();
      },

      updateTasaCambio(changeset, properyName, value) {
        changeset.set(properyName, parseFloat(value) || 0);
        this.updateDependentValues();
      },

      serializeArticulo(articulo) {
        return articulo ? articulo.get('codarticulo') : null;
      },

      serializeBodega(bodega) {
        return bodega ? bodega.get('codbodega') : null;
      },

      serializeUnidadMedida(uMedida) {
        return uMedida ? uMedida.get('codmedida') : null;
      },

      serializeCuenta(cuenta) {
        return cuenta ? cuenta.get('codcuenta') : null;
      },

      serializeCentroCosto(centroCosto) {
        return centroCosto ? centroCosto.get('codcc') : null;
      },

      serializeCentroIntegracion(centroIntegracion) {
        return centroIntegracion ? centroIntegracion.get('codci') : null;
      },

      editArticulo() {
        this.transitionToRoute(`${this.routeName}.articulo`);
      },

      cancelEdit() {
        // The route will destroy the record if it's new.
        this.transitionToRoute(this.indexRoute);
      }

    }
  });

  _exports.default = _default;
});