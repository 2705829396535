define("sirvo-web/controllers/inventario/movimientos/salidas", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/models/kar-mov", "sirvo-web/utils/date"], function (_exports, _abstractModuleIndex, _karMov, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    router: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    modelClass: _karMov.default,
    reload: true,
    tipoMovimiento: 'SALIDAS',
    currentYear: Ember.computed(function () {
      return new Date().getFullYear();
    }),
    currentMonth: Ember.computed(function () {
      return String(new Date().getMonth() + 1).padStart(2, '0');
    }),
    currentYearMonth: Ember.computed('currentYear', 'currentMonth', function () {
      return `${this.currentYear}${this.currentMonth}`;
    }),
    years: (0, _date.lastYears)(),
    months: _date.monthIndexes,
    routeName: Ember.computed(function () {
      return this.router.currentRouteName.split('.').slice(0, -1).join('.');
    }),
    modelFilters: Ember.computed('tipoMovimiento', 'currentYearMonth', function () {
      return [{
        selectedFilter: {
          filterName: 'empresa'
        },
        value: this.company.get('id')
      }, {
        selectedFilter: {
          filterName: 'deha'
        },
        value: {
          exact: 'H'
        }
      }, {
        selectedFilter: {
          filterName: 'tipoMovimiento.tipomov'
        },
        value: {
          exact: this.tipoMovimiento
        }
      }, {
        selectedFilter: {
          filterName: 'yearmes'
        },
        value: {
          exact: this.currentYearMonth
        }
      }];
    }),
    modelSortingProperties: Ember.computed(function () {
      return [{
        propertyName: 'fecha'
      }, {
        propertyName: 'correlativo',
        ascending: true
      }];
    }),
    columns: Ember.computed(function () {
      return [{
        label: 'ID',
        valuePath: 'id',
        width: '80px'
      }, {
        label: 'Fecha',
        valuePath: 'fechaFormatted',
        sortingPropertyName: 'fecha',
        width: '110px'
      }, {
        label: 'Tipo Doc.',
        valuePath: 'tipoDocumento.codtipo',
        width: '110px'
      }, {
        label: 'Serie',
        valuePath: 'serie',
        width: '90px',
        sortable: false
      }, {
        label: 'Número',
        valuePath: 'numero',
        width: '100px'
      }, {
        label: 'Valor',
        valuePath: 'valorNeto',
        width: '100px',
        sortable: false
      }, {
        label: 'Movimiento',
        valuePath: 'tipoMovimiento.tipomov',
        width: '100px',
        sortable: false
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.editMov.bind(this)
          },
          viewDetails: {
            title: 'Detalles',
            buttonClass: 'btn-warning',
            icon: 'list',
            action: this.actions.viewDetails.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }];
    }),
    actions: {
      viewDetails(record) {
        if (this.tipoMovimiento === 'TRASLADOS') {
          this.transitionToRoute(`${this.routeName}.traslado.detalles`, record.id);
        } else if (this.tipoMovimiento === 'AJUSTE') {
          this.transitionToRoute(`${this.routeName}.ajuste.detalles`, record.id);
        } else {
          this.transitionToRoute(`${this.routeName}.detalles`, record.id);
        }
      },

      addRecord() {
        if (this.tipoMovimiento === 'TRASLADOS') {
          this.transitionToRoute(`${this.routeName}.traslado`, 'new');
        } else if (this.tipoMovimiento === 'AJUSTE') {
          this.transitionToRoute(`${this.routeName}.ajuste`, 'new');
        } else {
          this.transitionToRoute(`${this.routeName}.record`, 'new');
        }
      },

      editMov(record) {
        if (!record.id) {
          return;
        }

        if (this.tipoMovimiento === 'TRASLADOS') {
          this.transitionToRoute(`${this.routeName}.traslado`, record.id);
        } else if (this.tipoMovimiento === 'AJUSTE') {
          this.transitionToRoute(`${this.routeName}.ajuste`, record.id);
        } else {
          this.transitionToRoute(`${this.routeName}.record`, record.id);
        }
      },

      delete(item, row, lightTable) {
        if (this.fechaCierre >= item.get('fecha')) {
          this.toast.error('Registro solo de lectura por cierre de inventario.');
          return;
        }

        const superRef = this._super.bind(this);

        this.loader.setLoading(true);
        return this.store.query('kar-movdet', {
          filter: {
            movimiento: item.id
          }
        }).then(detalles => {
          if (!detalles.length) {
            return superRef(item, row, lightTable);
          }

          this.toast.error('El movimiento posee detalles.');
        }).finally(() => this.loader.setLoading(false));
      },

      reloadTable() {
        this.set('reload', true);
      }

    }
  });

  _exports.default = _default;
});