define("sirvo-web/templates/components/kar/mov-det-ubicacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GGvu5v9o",
    "block": "{\"symbols\":[\"ubicacionChangesetInner\",\"f\",\"ubicacion\",\"@ubicacionesMeta\",\"@ubicacionesChangesets\",\"@ubicacionChangeset\"],\"statements\":[[4,\"extended-form-for\",[[21,6,[]]],null,{\"statements\":[[4,\"component\",[[21,2,[\"ace-power-select\"]],\"articuloUbicacion\"],[[\"label\",\"selected\",\"options\",\"placeholder\",\"searchField\",\"onchange\"],[[21,6,[\"tipoUbicacion\",\"nombre\"]],[21,6,[\"articuloUbicacion\"]],[26,\"get\",[[26,\"get\",[[21,4,[]],[21,6,[\"tipoUbicacion\",\"id\"]]],null],\"ubicaciones\"],null],\"Seleccionar...\",\"nombre\",[26,\"queue\",[[26,\"action\",[[21,0,[]],[26,\"mut\",[[21,6,[\"articuloUbicacion\"]]],null]],null],[26,\"action\",[[21,0,[]],\"onUpdate\",[21,6,[\"tipoUbicacion\"]]],null]],null]]],{\"statements\":[[0,\"    \"],[1,[21,3,[\"nombre\"]],false],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[2]},null],[0,\"\\n\"],[4,\"if\",[[21,6,[\"tipoUbicacion\",\"tipoUbicacionInferior\"]]],null,{\"statements\":[[4,\"each\",[[21,5,[]]],null,{\"statements\":[[4,\"if\",[[26,\"eq\",[[21,1,[\"tipoUbicacion\",\"id\"]],[21,6,[\"tipoUbicacion\",\"tipoUbicacionInferior\",\"id\"]]],null]],null,{\"statements\":[[0,\"      \"],[1,[26,\"kar/mov-det-ubicacion\",null,[[\"ubicacionChangeset\",\"ubicacionesChangesets\",\"ubicacionesMeta\",\"onUpdate\"],[[21,1,[]],[21,5,[]],[21,4,[]],[26,\"action\",[[21,0,[]],\"onUpdate\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/components/kar/mov-det-ubicacion.hbs"
    }
  });

  _exports.default = _default;
});