define("sirvo-web/routes/inventario/movimientos/salidas/ajuste/registro", ["exports", "servir-ember-utilities/routes/abstract-module-record", "sirvo-web/validations/kar-movdet-ajuste", "moment"], function (_exports, _abstractModuleRecord, _karMovdetAjuste, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    request: Ember.inject.service(),
    kardex: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    modelName: 'kar-movdet',
    parentRoute: 'inventario.movimientos.salidas.ajuste',
    indexRoute: 'inventario.movimientos.salidas.ajuste.detalles',
    routeRecordIdentifierDynamicSegment: 'detalle_id',
    validations: _karMovdetAjuste.default,
    relatedModelsToQuery: Ember.computed('isNew', function () {
      let modelsToQuery = [{
        name: 'tiposUbicaciones',
        modelName: 'kar-articulo-tipo-ubicacion',
        queryParams: {
          filter: {
            empresa: this.company.id
          }
        }
      }];

      if (!this.isNew) {
        modelsToQuery.push({
          name: 'ubicaciones',
          modelName: 'kar-mov-det-ubicacion',
          queryParams: {
            filter: {
              movimientoDetalle: this.recordId
            },
            include: 'articuloUbicacion'
          }
        });
      }

      return modelsToQuery;
    }),

    model() {
      let parentData = this.modelFor(this.parentRoute);

      if (!parentData.record.get('id')) {
        this.toast.error('Guarde el movimiento para poder registrar un detalle.');
        return this.transitionTo(`${this.parentRoute}.movimiento`);
      }

      return this._super(...arguments).then(modelData => {
        if (modelData.record.get('isNew')) {
          modelData.record.set('movimiento', parentData.record);
        }

        let fecha = (0, _moment.default)(parentData.record.get('fecha'));
        let relationships = [modelData.record.get('articulo'), modelData.record.get('bodega'), modelData.record.get('unidadMedida'), modelData.record.get('cuenta'), modelData.record.get('centroCosto'), modelData.record.get('centroIntegracion')];
        return Ember.RSVP.all(relationships).then(() => Ember.RSVP.hash({
          periodo: this.store.query('emp-periodo', {
            filter: {
              empresa: this.company.id,
              feciniperiodo: {
                before: fecha.format('YYYY-MM-DD')
              },
              fecfinperiodo: {
                after: fecha.format('YYYY-MM-DD')
              }
            }
          })
        })).then(({
          periodo
        }) => {
          let fechaFormatted = fecha.format('DD/MM/YYYY');

          if (periodo.length === 0) {
            this.toast.error(`No se encontró el período fiscal para la fecha ${fechaFormatted}.`);
            return this.transitionTo(this.indexRoute);
          } else if (periodo.length > 1) {
            this.toast.error(`Más de un período fiscal encontrado para la fecha ${fechaFormatted}.`);
            return this.transitionTo(this.indexRoute);
          }

          return Ember.merge(modelData, {
            periodo: periodo.get('firstObject')
          });
        });
      }).then(modelData => {
        let cuentaId = modelData.record.belongsTo('cuenta').id();

        if (!cuentaId) {
          return modelData;
        }

        let nomenclaturaId = modelData.periodo.belongsTo('nomenclatura').id();
        return Ember.RSVP.hash({
          tipoCuentaData: this.request.get(`emp-nomenclaturas/${cuentaId}/tipo`, {
            nomenclaturaId
          })
        }).then(({
          tipoCuentaData
        }) => Ember.merge(modelData, {
          tipoCuenta: tipoCuentaData.data.tipo
        }));
      });
    },

    createRecordInstance() {
      return this.store.createRecord(this.modelName, {
        empresa: this.company
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      let parentData = this.modelFor(this.parentRoute);
      controller.set('movimiento', parentData.record);

      if (this.fechaCierre >= parentData.record.get('fecha')) {
        this.toast.warning('Registro solo de lectura por cierre de inventario.');
      }

      controller.set('ubicacionesChangesets', this.kardex.createMovDetUbicacionesChangesets(model.tiposUbicaciones, model.record));
    },

    actions: {
      willTransition(transition) {
        if (transition.targetName.startsWith(this.routeName)) {
          return false;
        }

        if (this.controller.get('changeset').get('isDirty')) {
          transition.abort();
          this.send('openModal', 'popups/simple-confirm', {
            title: 'La forma se ha modificado. ¿Está seguro de descartar sus cambios?',
            message: '',
            onSubmit: () => {
              this.controller.discardChanges();
              transition.retry();
            }
          });
          return false;
        }

        this.controller.discardChanges();
      }

    }
  });

  _exports.default = _default;
});