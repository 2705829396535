define("sirvo-web/controllers/inventario/procesos/cierre-costos", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    request: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    actions: {
      updateEsCierre(changeset, properyName, value) {
        changeset.set(properyName, value);
        changeset.set('esApertura', !changeset.get('esCierre'));
        changeset.set('fecha', new Date());
      },

      updateEsApertura(changeset, properyName, value) {
        changeset.set(properyName, value);
        changeset.set('esCierre', !changeset.get('esApertura'));
        changeset.set('fecha', null);
      },

      submit() {
        return this.changeset.validate().then(() => {
          if (!this.changeset.get('isValid')) {
            this.toast.error('Hay errores en el formulario.');
            return;
          }

          this.loader.setLoading(true);
          const momentDate = this.changeset.get('fecha') ? (0, _moment.default)(this.changeset.get('fecha')) : null;
          return this.request.get('kar-cierre-costo', {
            empresaId: this.changeset.get('empresa.id'),
            fecha: momentDate ? momentDate.format('YYYY-MM-DD') : null,
            esApertura: this.changeset.get('esApertura') ? 1 : 0,
            motivo: this.changeset.get('motivo') || null
          }).then(() => {
            this.toast.success('Proceso realizado correctamente.');
            return this.currentCompany.loadLastCierre().then(() => {
              this.changeset.set('lastCierreFecha', this.fechaCierre || null);
            });
          }).catch(error => this.errorHandler.handleBackendErrors(error)).finally(() => this.loader.setLoading(false));
        });
      }

    }
  });

  _exports.default = _default;
});