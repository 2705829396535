define("sirvo-web/routes/inventario/procesos/inventario-fisico/articulos/record/bodega", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    model() {
      let superData = this._super(...arguments);

      let inventarioFisico = superData.record.get('inventarioFisico');

      if (inventarioFisico.get('cerrado')) {
        this.toast.warning('Inventario cerrado.');
        return this.transitionTo(this.parentRoute);
      }

      if (inventarioFisico.get('bodega.id')) {
        this.toast.warning('No se puede modificar porque el inventario está asociado a una bodega.');
        return this.transitionTo(this.parentRoute);
      }

      let indexCtrl = this.controllerFor(this.parentRoute);

      if (!indexCtrl.changeset) {
        return this.transitionTo(this.parentRoute);
      }

      if (!indexCtrl.changeset.get('articulo.id')) {
        this.toast.error('Debe seleccionar primero un artículo.');
        return this.transitionTo(this.parentRoute);
      }

      return Ember.RSVP.hash({
        bodegasDet: this.store.query('kar-bodega-det', {
          filter: {
            articulo: indexCtrl.changeset.get('articulo.id'),
            habilitado: 1
          },
          include: 'bodega'
        })
      }).then(({
        bodegasDet
      }) => ({ ...superData,
        bodegas: bodegasDet.mapBy('bodega')
      }));
    }

  });

  _exports.default = _default;
});