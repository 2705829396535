define("sirvo-web/routes/inventario/procesos/inventario-fisico/index/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    modelName: 'kar-inventario-fisico',
    indexRoute: 'inventario.procesos.inventario-fisico.index',
    relatedModelsToQuery: Ember.computed('isNew', function () {
      let queryMeta = [{
        name: 'bodegas',
        modelName: 'kar-bodega'
      }];

      if (this.isNew) {
        queryMeta.push({
          name: 'conteoActivo',
          presetValue: Ember.A([])
        });
      } else {
        queryMeta.push({
          name: 'conteoActivo',
          modelName: 'kar-inventario-fisico-conteo',
          queryParams: {
            filter: {
              inventarioFisico: this.recordId,
              page_number: 1,
              page_size: 1
            },
            sort: '-numero'
          }
        });
      }

      return queryMeta;
    }),
    validations: Ember.computed(function () {
      return {
        empresa: (0, _validators.validatePresence)({
          presence: true
        }),
        fecha: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    model() {
      return this._super(...arguments).then(modelData => ({ ...modelData,
        conteoActivo: modelData.conteoActivo.get('firstObject')
      }));
    },

    createRecordInstance() {
      return this.store.createRecord(this.modelName, {
        empresa: this.company,
        fecha: new Date()
      });
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});