define("sirvo-web/controllers/inventario/movimientos/ingresos/existencia-inicial/registro/articulo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    kardex: Ember.inject.service(),
    actions: {
      onClose() {
        this.transitionToRoute(this.parentRoute);
      },

      articuloUpdated() {
        this.kardex.onChangesetArticuloUpdated(this.changeset).then(articuloData => {
          this.setProperties(articuloData);
        });
      },

      bodegaUpdated() {
        // Resetear el valor seleccionado de cada ubicación.
        this.ubicacionesChangesets.forEach(ubicacionChangeset => {
          ubicacionChangeset.set('articuloUbicacion', null);
        });
        this.kardex.updateBodegaUbicaciones(this.changeset.get('bodega'), this.ubicacionesMeta);
      },

      unidadMedidaUpdated() {
        this.kardex.onChangesetUnidadMedidaUpdated(this.changeset);
      },

      ubicacionUpdated(tipoUbicacion, articuloUbicacion) {
        // Resetear el valor seleccionado de las ubicaciones dependientes.
        this.ubicacionesChangesets.forEach(ubicacionChangeset => {
          if (ubicacionChangeset.get('tipoUbicacion').get('tipoUbicacionSuperior.id') === tipoUbicacion.get('id')) {
            ubicacionChangeset.set('articuloUbicacion', null);
          }
        });
        this.kardex.updateUbicacionSubDivisiones(tipoUbicacion, articuloUbicacion, this.ubicacionesMeta);
      }

    }
  });

  _exports.default = _default;
});