define("sirvo-web/services/current-company", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    company: null,
    modulos: null,
    karParameters: null,
    lastCierre: null,
    kardexModule: Ember.computed.filterBy('modulos', 'directorio', 'KARDEX'),

    load() {
      const token = this.session.data.authenticated.token;

      if (!token) {
        throw Error('User not authenticated.');
      }

      const authenticator = Ember.getOwner(this).lookup('authenticator:jwt');
      const userData = authenticator.getTokenData(token).username.split('@', 2);

      if (!userData || userData.length < 2) {
        throw Error('Empresa code not found from session data.');
      }

      const codempresa = userData[1];
      this.loader.setLoading(true);
      return this.store.query('empresa', {
        filter: {
          codempresa: {
            exact: codempresa
          }
        }
      }).then(result => {
        if (0 === result.get('length')) {
          throw Error(`Got no record for empresa '${codempresa}'.`);
        }

        if (1 !== result.get('length')) {
          throw Error(`Got more than one record for empresa '${codempresa}'.`);
        }

        this.set('company', result.get('firstObject'));
      }).then(() => this.loadModulos()).then(() => this.loadKarParameters()).then(() => this.loadLastCierre()).then(() => this.company).catch(error => {
        this.toast.error('No es posible acceder en este momento.');
        throw error;
      }).finally(() => this.loader.setLoading(false));
    },

    loadModulos() {
      return this.store.query('modulo', {}).then(modulos => {
        this.set('modulos', modulos);
      });
    },

    loadKarParameters() {
      if (!this.company || !this.modulos || !this.kardexModule.length) {
        return Ember.RSVP.resolve(true);
      }

      return this.store.query('kar-parametro', {
        filter: {
          empresa: this.company.id
        }
      }).then(karParameters => {
        if (1 !== karParameters.get('length')) {
          return Ember.RSVP.resolve(true); // throw Error(`Got more than one 'kar-parametro' for empresa '${this.company.codempresa}'.`);
        }

        this.set('karParameters', karParameters.get('firstObject'));
      });
    },

    loadLastCierre() {
      if (!this.company || !this.modulos || !this.kardexModule.length) {
        return Ember.RSVP.resolve(true);
      }

      return this.store.query('kar-cierre', {
        filter: {
          empresa: this.company.id,
          page_number: 1,
          page_size: 1
        },
        sort: '-fecha'
      }).then(lastCierre => {
        this.set('lastCierre', lastCierre.get('firstObject'));
      });
    }

  });

  _exports.default = _default;
});