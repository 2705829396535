define("sirvo-web/controllers/inventario/procesos/inventario-fisico/articulos/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexCtrl: Ember.inject.controller('inventario.procesos.inventario-fisico.articulos'),
    indexRoute: null,
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),
    articuloActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editArticulo.bind(this)
      }];
    }),
    bodegaActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editBodega.bind(this)
      }];
    }),

    postSave()
    /*record*/
    {
      this.indexCtrl.reloadTable();
    },

    actions: {
      onClose() {
        this.record.rollbackAttributes();
        this.transitionToRoute(this.indexRoute);
      },

      serializeArticulo(articulo) {
        return articulo ? articulo.get('codarticulo') : null;
      },

      serializeBodega(bodega) {
        return bodega ? bodega.get('codbodega') : null;
      },

      serializeConteo(conteoActivo) {
        return conteoActivo ? conteoActivo.get('descripcion') : '';
      },

      editArticulo() {
        this.transitionToRoute(`${this.routeName}.articulo`);
      },

      editBodega() {
        this.transitionToRoute(`${this.routeName}.bodega`);
      }

    }
  });

  _exports.default = _default;
});