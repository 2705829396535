define("sirvo-web/models/kar-cierre", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    empresa: (0, _model.belongsTo)('empresa'),
    correlativo: (0, _model.attr)('number'),
    fecha: (0, _model.attr)('date')
  });

  _exports.default = _default;
});