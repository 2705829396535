define("sirvo-web/controllers/inventario/procesos/inventario-fisico/index/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexCtrl: Ember.inject.controller('inventario.procesos.inventario-fisico.index'),
    indexRoute: null,
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),
    conteoActivo: null,

    postSave()
    /*record*/
    {
      this.indexCtrl.reloadTable();
    },

    actions: {
      onClose() {
        this.record.rollbackAttributes();
        this.transitionToRoute(this.indexRoute);
      },

      serializeConteoActivo(conteoActivo) {
        return conteoActivo ? conteoActivo.get('descripcion') : '';
      },

      cerrarInventario() {
        this.send('openModal', 'popups/simple-confirm', {
          title: 'Cerrar inventario',
          message: '¿Está seguro que quiere cerrar el inventario? Ya no se podrá modificar.',
          onSubmit: () => {
            this.changeset.set('cerrado', true);
            this.submit();
          }
        });
      },

      abrirInventario() {
        this.send('openModal', 'popups/simple-confirm', {
          title: 'Cerrar inventario',
          message: '¿Está seguro que quiere abrir el inventario?',
          onSubmit: () => {
            this.changeset.set('cerrado', false);
            this.submit();
          }
        });
      },

      generarNuevoConteo() {
        if (!this.record.get('id')) {
          return;
        }

        this.send('openModal', 'popups/simple-confirm', {
          title: 'Generar nuevo conteo',
          message: '¿Está seguro que quiere habilitar un nuevo conteo?',
          onSubmit: () => {
            let conteo = this.store.createRecord('kar-inventario-fisico-conteo', {
              inventarioFisico: this.record,
              fecha: new Date()
            });
            this.loader.setLoading(true);
            return conteo.save().then(() => this.store.query('kar-inventario-fisico-conteo', {
              filter: {
                inventarioFisico: this.record.get('id'),
                page_number: 1,
                page_size: 1
              },
              sort: '-numero'
            })).then(conteoActivo => {
              this.set('conteoActivo', conteoActivo.get('firstObject'));
            }).catch(() => {
              this.toast.error('No se pudo generar el siguiente conteo.');
            }).finally(() => this.loader.setLoading(false));
          }
        });
      }

    }
  });

  _exports.default = _default;
});